import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVimeo, faInstagram } from '@fortawesome/free-brands-svg-icons'
import YouTube from 'react-youtube'

import Header from '../../components/Header/Header'
import Container, { Spaced } from '../../components/Container/Container'
import Title from '../../components/Title/Title'
import Text from '../../components/Text/Text'
import { View } from '../../components/View/View'
import ScrollableAnchor from 'react-scrollable-anchor'
import { Visible } from '../../components/Visible/Visible'

import itunes from '../../assets/images/logos/itunes.png'
import vudu from '../../assets/images/logos/vudu.png'
import google from '../../assets/images/logos/google-play-badge.png'
import vimeo from '../../assets/images/logos/vimeo-logo-white.png'
import amazon from '../../assets/images/logos/amazon.png'
import appleTv from '../../assets/images/logos/apple-tv.png'
import xbox from '../../assets/images/logos/xbox.png'

import wsl from '../../assets/images/clients/wsl.png'
import redbull from '../../assets/images/clients/redbull.png'
import netgeo from '../../assets/images/clients/netgeo.png'
import surfer from '../../assets/images/clients/surfer.png'
import globo from '../../assets/images/clients/globo.png'
import off from '../../assets/images/clients/canal-off.png'
import vans from '../../assets/images/clients/vans.png'
import oneill from '../../assets/images/clients/oneill.png'
import banner from '../../assets/images/banner/banner-creditos.png'
import kalani from '../../assets/images/banner/banner-premios.png'
import nuno from '../../assets/images/about/nuno.png'

import waves from '../../assets/images/banner/waves.png'

import Vimeo from '@u-wave/react-vimeo'
import styled from 'styled-components'
import design from '../../config/design'
import { videos } from './Home.mock'

const MobileWrap = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    flex-wrap: wrap;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    flex-wrap: nowrap;
  }
  @media screen and (min-width: ${design.breakpoints.desktopXL}px) {
    flex-wrap: nowrap;
  }
`
const VisiblePadding = styled.div`
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    padding-top: 1rem;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    padding-top: 0;
    padding-left: 2rem;
  }
  @media screen and (min-width: ${design.breakpoints.desktopXL}px) {
    padding-top: 0;
    padding-left: 2rem;
  }
`

const VisibleFlex = styled.img`
  display: ${(props) =>
    !props.mobile ? 'none' : props.inline ? 'inline-block' : 'flex'};
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    display: ${(props) =>
      !props.tablet ? 'none' : props.inline ? 'inline-block' : 'flex'};
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    display: ${(props) =>
      !props.desktop ? 'none' : props.inline ? 'inline-block' : 'flex'};
  }
  @media screen and (min-width: ${design.breakpoints.desktopXL}px) {
    display: ${(props) =>
      !props.desktopXL ? 'none' : props.inline ? 'inline-block' : 'flex'};
  }
`

const BackgroundImage = styled.div`
  background-image: url(${waves});
  background-repeat: no-repeat;
  height: 55vh;
  width: 100%;
  background-size: cover;
`

export default class Home extends Component {
  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo()
  }
  render() {
    const opts = {
      height: '390',
      width: '640',
    }
    const optsMobile = {
      height: '390',
      width: '100%',
    }
    return (
      <>
        <Header />
        <Vimeo
          video="371174753"
          autoplay
          loop
          responsive={true}
          background={true}
        />
        <ScrollableAnchor id="about">
          <View top={5} bottom={5}>
            <Title size={20} sizeTablet={22} sizeDesktop={28} center>
              ABOUT
            </Title>
            <View top={2} />
            <Container>
              <MobileWrap>
                <Text left size={12} sizeTablet={20} sizeDesktop={22}>
                  Born in Lisbon and local to Carcavelos beach, Nuno Dias
                  started early and with just 12 years of age to dedicate
                  himself to Surf photography. With a graduation in Audiovisual
                  and Multimedia at Escola Superior de Comunicação Social where
                  he started making his first film projects, the transition
                  became really natural for him. Traveling is one of his biggest
                  passions and where he learned and got amazing experiences
                  shooting some of the best surfers on the best waves in the
                  world.
                  <br />
                  <br />
                  <br />
                  <br />
                  Now with 25 years old and focused on video, Nuno has been
                  involved in some big productions shooting for films,
                  documentaries, advertising and social media content. He has
                  captured some of Nazaré's most iconic moments in recent years,
                  winning multiple film awards.
                </Text>

                <View left={2} top={1} />
                <VisibleFlex
                  desktop
                  desktopXL
                  src={nuno}
                  weight="auto"
                  height="600"
                  alt="Nuno Dias"
                />
              </MobileWrap>
            </Container>
          </View>
        </ScrollableAnchor>

        <ScrollableAnchor id="featured">
          <View top={5} bottom={5}>
            <Title size={20} sizeTablet={22} sizeDesktop={28} center>
              FEATURED
            </Title>

            <View top={2} />
            <Container>
              <div></div>
              <View top={1.5}>
                <Vimeo video="371174753" responsive={true} />
                <View top={1} />
                <Text left size={12} sizeTablet={18} sizeDesktop={18}>
                  A short documentary film featuring waterman Kalani Lattanzi
                  challenging the boundaries of Big Wave Surfing. Filmed in
                  Nazaré and Rio de Janeiro, he faces the biggest waves in the
                  world, in the most pure form of wave riding: bodysurf.
                </Text>
                <View top={2} />
                <Spaced style={{ flexWrap: 'wrap' }}>
                  <img
                    src={banner}
                    width="570px"
                    height="100%"
                    alt="kalani banner créditos"
                  />
                  <img
                    src={kalani}
                    width="570px"
                    height="100%"
                    alt="kalani banner prêmios"
                  />
                </Spaced>

                <View top={2} />

                <Title left size={20} sizeTablet={22} sizeDesktop={28} noLetter>
                  AVALIABLE NOW:{' '}
                </Title>
                <View top={2} />
                <Spaced style={{ flexWrap: 'wrap' }}>
                  <a
                    href="https://itunes.apple.com/us/movie/kalani-gift-from-heaven/id1510810500"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={itunes} alt="itunes logo" width="110px" />
                  </a>
                  <View left={2} />
                  <a
                    href="https://tv.apple.com/us/movie/kalani-gift-from-heaven/umc.cmc.4e40y71o95v6rswovcet6glxh"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={appleTv} alt="apple tv logo" width="110px" />
                  </a>
                  <View left={2} />
                  <a
                    href="https://vimeo.com/ondemand/kalanigiftfromheaven"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={vimeo} alt="vimeo logo" width="110px" />
                  </a>
                  <View left={2} />

                  <a
                    href="https://www.vudu.com/content/movies/details/Kalani-Gift-from-Heaven/1422664"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={vudu} alt="vudu logo" width="110px" />
                  </a>
                  <View left={2} />

                  <a
                    href="https://www.amazon.com/Kalani-Gift-Heaven-Lattanzi/dp/B0893QH16D/ref=sr_1_2?dchild=1&keywords=kalani&qid=1590503172&s=instant-video&sr=1-2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={amazon} alt="amazon logo" width="110px" />
                  </a>
                  <View left={2} />

                  <a
                    href="https://www.microsoft.com/en-us/p/kalani-gift-from-heaven/8d6kgwxn6t01?activetab=pivot%3aoverviewtab"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={xbox} alt="xbox logo" width="130px" />
                  </a>
                  <View left={2} />

                  <a
                    href="https://play.google.com/store/movies/details/Kalani_Gift_from_Heaven?id=r10wkgoqIn4.P"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={google} alt="google play logo" width="130px" />
                  </a>
                </Spaced>
              </View>
            </Container>
          </View>
        </ScrollableAnchor>

        <ScrollableAnchor id="work">
          <View top={5} bottom={5}>
            <Title size={20} sizeTablet={22} sizeDesktop={28} center>
              WORK
            </Title>
            <View top={5} />
            <Container>
              {videos.map((content) => (
                <View bottom={3}>
                  <Visible mobile>
                    {content.youtube ? (
                      <YouTube
                        videoId={content.id}
                        opts={optsMobile}
                        onReady={this._onReady}
                      />
                    ) : content.img ? (
                      <a
                        href={content.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={content.mobile ? content.mobile : content.id}
                          width={optsMobile.width}
                          height={optsMobile.height}
                          alt={content.name}
                        />
                      </a>
                    ) : (
                      <Vimeo video={content.id} responsive />
                    )}
                  </Visible>
                  <MobileWrap>
                    <Visible desktopXL desktop tablet>
                      {content.youtube ? (
                        <YouTube
                          videoId={content.id}
                          opts={opts}
                          onReady={this._onReady}
                        />
                      ) : content.img ? (
                        <a
                          href={content.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={content.id}
                            width={content.width || 900}
                            height={opts.height}
                            alt={content.name}
                          />
                        </a>
                      ) : (
                        <Vimeo video={content.id} />
                      )}
                    </Visible>
                    <VisiblePadding>
                      <Text left size={12} sizeTablet={16} sizeDesktop={16}>
                        {content.description}
                        <br />
                        <br />
                        {content.url ? (
                          <>
                            See more:
                            <strong>
                              {' '}
                              <a
                                style={{ color: '#fff' }}
                                href={content.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {content.name}
                              </a>
                            </strong>
                          </>
                        ) : null}
                      </Text>
                    </VisiblePadding>
                  </MobileWrap>
                </View>
              ))}
            </Container>
          </View>
        </ScrollableAnchor>
        <ScrollableAnchor id="clients">
          <View top={5} bottom={5}>
            <Title size={20} sizeTablet={22} sizeDesktop={28} center>
              CLIENTS
            </Title>
            <View top={4} />
            <Container>
              <Spaced style={{ flexWrap: 'wrap' }}>
                <img src={wsl} alt="wsl logo" width="100px" height="100px" />
                <img
                  src={redbull}
                  alt="redbull logo"
                  width="100px"
                  height="100px"
                />
                <img
                  src={netgeo}
                  alt="netgeo logo"
                  width="100px"
                  height="100px"
                />
                <img
                  src={surfer}
                  alt="surfer logo"
                  width="100px"
                  height="100px"
                />
                <img
                  src={globo}
                  alt="globo logo"
                  width="100px"
                  height="100px"
                />
                <img
                  src={off}
                  alt="canal off logo"
                  width="100px"
                  height="100px"
                />
                <img src={vans} alt="vans" width="100px" height="100px" />
                <img src={oneill} alt="o'neill" width="100px" height="100px" />
              </Spaced>
            </Container>
          </View>
        </ScrollableAnchor>
        <ScrollableAnchor id="contact">
          <View top={3}>
            <BackgroundImage>
              <View top={1} />
              <Title size={20} sizeTablet={22} sizeDesktop={28} center>
                CONTACT
              </Title>
              <View top={5} />
              <Container>
                <Text center size={20} sizeTablet={24} sizeDesktop={24}>
                  <a
                    href="mailto:nuno_dias@icloud.com"
                    style={{
                      color: 'white',
                      textDecoration: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    nuno_dias@icloud.com
                  </a>
                </Text>
                <View top={2} />
                <Text center size={20} sizeTablet={24} sizeDesktop={24}>
                  <a
                    style={{
                      color: 'white',
                      textDecoration: 'none',
                      cursor: 'pointer',
                    }}
                    href="https://api.whatsapp.com/send?phone=+351912063073&text=Hello, Nuno Dias."
                  >
                    +351 912 063 073
                  </a>
                </Text>
                <View top={2} />
                <View top={2} />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <a href="https://www.instagram.com/nuno_diass/">
                    <FontAwesomeIcon
                      icon={faInstagram}
                      style={{ color: 'white' }}
                      size={'2x'}
                    />
                  </a>
                  <View left={3} />
                  <a href="https://vimeo.com/nunodiass">
                    <FontAwesomeIcon
                      icon={faVimeo}
                      style={{ color: 'white' }}
                      size={'2x'}
                    />
                  </a>
                </div>
              </Container>
              <View top={2} />
            </BackgroundImage>
          </View>
        </ScrollableAnchor>
      </>
    )
  }
}
