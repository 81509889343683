import styled from 'styled-components'

import design from '../../config/design'

export const Visible = styled.div`
  display: ${(props) =>
    !props.mobile ? 'none' : props.inline ? 'inline-block' : 'block'};
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    display: ${(props) =>
      !props.tablet ? 'none' : props.inline ? 'inline-block' : 'block'};
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    display: ${(props) =>
      !props.desktop ? 'none' : props.inline ? 'inline-block' : 'block'};
  }
  @media screen and (min-width: ${design.breakpoints.desktopXL}px) {
    display: ${(props) =>
      !props.desktopXL ? 'none' : props.inline ? 'inline-block' : 'block'};
  }
`
