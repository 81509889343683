import styled, { css } from 'styled-components'
import design from '../../config/design'

const containerStyles = css`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0;

  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    max-width: ${design.breakpoints.tablet}px;
    padding: 0 50px;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    max-width: ${design.breakpoints.desktop}px;
  }
  @media screen and (min-width: ${design.breakpoints.desktopXL}px) {
    max-width: ${design.breakpoints.desktopXL}px;
  }
`

const Container = styled.div`
  ${containerStyles}
`

const Spaced = styled.div`
  display: flex;
  justify-content: space-between;
`

const Flex = styled.div`
  display: flex;
`

const ContainerFull = styled.div`
  background-color: ${(props) => props.color};
  border-radius: ${(props) => (props.rounded ? '5px' : null)};
`

const ContainerNoMargin = styled.div`
  margin: 0 1.5rem;
`

export default Container
export { Spaced, ContainerFull, ContainerNoMargin, Flex }
