import styled from 'styled-components'

export const View = styled.div`
  padding-top: ${(props) => props.top}rem;
  padding-left: ${(props) => props.left}rem;
  padding-right: ${(props) => props.right}rem;
  padding-bottom: ${(props) => props.bottom}rem;
  display: ${(props) => (props.center ? 'flex' : null)};
  justify-content: ${(props) => (props.center ? 'center' : null)};
`
