import design from '../../config/design'
import styled from 'styled-components'

const Text = styled.p`
  font-family: 'Gotham';
  line-height: 20px;

  color: ${(props) => props.color || design.colors.white};
  font-size: ${(props) => props.size}px;
  text-align: ${(props) => (props.center ? 'center' : props.align)};

  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    font-size: ${(props) => props.sizeTablet}px;
    text-align: ${(props) => (props.centerTablet ? 'center' : props.align)};
    line-height: 28px;
  }

  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    font-size: ${(props) => props.sizeDesktop}px;
    text-align: ${(props) => (props.centerDesktop ? 'center' : props.align)};
    line-height: 28px;
  }
`

export default Text
