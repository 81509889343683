import design from '../../config/design'
import styled from 'styled-components'

const Title = styled.h1`
  text-align: ${(props) => (props.center ? 'center' : props.align)};
  font-family: 'Helserif';
  letter-spacing: ${(props) => (!props.noLetter ? '10px' : '3px')};

  color: ${(props) => props.color || design.colors.white};
  font-size: ${(props) => props.size}px;

  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    font-size: ${(props) => props.sizeTablet}px;
  }

  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    font-size: ${(props) => props.sizeDesktop}px;
  }
`

export default Title
