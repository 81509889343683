const design = {
  breakpoints: {
    mobile: 0,
    tablet: 640,
    desktop: 960,
    desktopXL: 1280,
  },
  colors: {
    white: '#ffffff',

    grey050: '#efeff0',
    grey100: '#f0f0f1',
    grey200: '#f5f0f6',

    dark000: '#000000',
    dark050: '#0D0D0B',
  },
}
export default design
