import React from 'react'
import design from '../../config/design'

import styled from 'styled-components'
import { Visible } from '../Visible/Visible'
import { View } from '../View/View'
import { Spaced } from '../Container/Container'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVimeo, faInstagram } from '@fortawesome/free-brands-svg-icons'

const StyledHeader = styled.div`
  background-color: ${design.colors.dark050};
  color: ${design.colors.white};
`

const Logo = styled.h2`
  font-family: 'Helserif';
  letter-spacing: 0.3rem;
  white-space: nowrap;

  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    font-size: 1rem;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    font-size: 1.5rem;
  }
  @media screen and (min-width: ${design.breakpoints.desktopXL}px) {
    font-size: 2rem;
  }
`

const Menu = styled.a`
  text-decoration: none;
  color: #fff;
  font-size: 0.83rem;
  font-family: 'Helserif';
  letter-spacing: 0.4rem;
  cursor: pointer;
  text-transform: uppercase;
  transition: ease-in-out 0.2s;
  &:hover {
    color: #23d7ff;
  }
`

const MenuContent = [
  {
    name: 'Featured',
    url: '#featured',
  },
  {
    name: 'About',
    url: '#about',
  },

  {
    name: 'Work',
    url: '#work',
  },
  {
    name: 'Clients',
    url: '#clients',
  },
  {
    name: 'Contact',
    url: '#contact',
  },
]

const Header = () => (
  <StyledHeader>
    <Spaced>
      <View top={1.8} bottom={1} left={3}>
        <View right={3}>
          <Logo>NUNO DIAS</Logo>
        </View>
      </View>
      <Visible desktopXL>
        <View top={1.8} bottom={1}>
          <Spaced>
            <View left={4} />
            {MenuContent.map((content, i) => (
              <View key={i} right={2}>
                <Menu href={content.url}>{content.name}</Menu>
              </View>
            ))}
          </Spaced>
        </View>
      </Visible>
      <Visible mobile tablet>
        <View top={1} />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a href="https://www.instagram.com/nuno_diass/">
            <FontAwesomeIcon
              icon={faInstagram}
              style={{ color: 'white' }}
              size={'2x'}
            />
          </a>
          <View left={2} />
          <a href="https://vimeo.com/nunodiass">
            <FontAwesomeIcon
              icon={faVimeo}
              style={{ color: 'white' }}
              size={'2x'}
            />
          </a>
          <View right={2} />
        </div>
      </Visible>
    </Spaced>
  </StyledHeader>
)

export default Header
