import joshua from '../../assets/images/works/joshua.jpg'
import tender from '../../assets/images/works/tender.jpg'
import tenderMobile from '../../assets/images/works/tender-mobile.png'

export const videos = [
  {
    id: 'QmeRCzEBPg4',
    youtube: true,
    name: 'Hostile Planet',
    description:
      'Hostile Planet is an innovative series on wildlife that explores the most hostile environments on planet Earth. Over six episodes are revealed the ways in which animals have adapted to survive.',
    url: 'https://www.natgeo.pt/video/tv/hostile-planet',
  },
  {
    id: '244016156',
    name: 'Aston Martin',
    description:
      'Stock footage for the advertising of the Aston Martin - Vantage.',
    url: 'https://vimeo.com/244016156',
  },
  {
    id: 'GjRhiLzWZDQ',
    youtube: true,
    name: 'Maya Gabeira',
    description:
      'Guiness World Record - Maya Gabeira charges into the biggest wave ever ridden by a woman at Praia do Norte, Nazaré, Portugal on January 18, 2018.',
    url: '',
  },
  {
    id: tender,
    mobile: tenderMobile,
    img: true,
    width: 1920,
    name: 'Tender',
    description:
      "A documentary that travels chronologically through the transformative moments of Pierre's career, which are directly linked with some of the most iconic moments in the Bodyboarding history.",
    url: 'https://movementmag.com/watch/tender?rq=tender',
  },
  {
    id: '271313438',
    name: 'Empties',
    description:
      'A shortfilm featuring the many faces and expressions of the waves of Nazaré.',
    url: '',
  },
  {
    id: joshua,
    img: true,
    name: 'Redbull Slackline',
    description:
      'The slackliner Joshua Leupolz puts himself at the mercy of the biggest waves in Nazaré.',
    url: 'https://www.redbull.com/car-en/slackline-in-nazare',
  },
  {
    id: '0kytCAj4TbI',
    youtube: true,
    name: 'Surfer/Vans',
    description:
      'A behind the scenes look at the 2019 Vans Duct Tape Festival in Ericeira, Portugal',
    url: 'https://www.surfer.com/videos/duct-tape-festival-ericeira-portugal/',
  },

  {
    id: '212832836',
    name: 'Grande',
    description:
      'A shortfilm featuring the highlights of 2016/2017 Winter at Nazaré.',
  },
]
